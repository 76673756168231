<template>
  <base-section
      id="people"
  >
    <v-responsive
        class="mx-auto"
        max-width="1350"
    >
      <v-container fluid>
        <base-section-heading
            :title="$t('management_team')"/>
        <v-row>
          <v-col cols="0" md="2"></v-col>
          <v-col
              v-for="card in cards[$i18n.locale]"
              :key="card.name"
              cols="12"
              md="4"
          >
            <base-person-card
                align="center"
                :dark="false"
                v-bind="card"
            />
          </v-col>
        </v-row>
        <base-section-heading
            :title="$t('staff')"/>
        <p class="text-center">{{ $t('staff_text') }}</p>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: 'SectionPeople',

  data: () => ({
    cards: {
      en: [
        {
          image: 'anna_lindstedt.jpg',
          name: 'Anna Lindstedt',
          title: 'Partner & Senior Advisor',
          text: 'Anna Lindstedt is one of Sweden\'s foremost experts on strategic sustainability work in general and supply chain management in particular. In the role of Senior Advisor, Lead Auditor and former CEO of Ethos International, she has helped a large number of global companies with requirements and screening as well as full-scale audits.',
          email: 'anna.lindstedt@evaluate.se',
          linkedin: 'https://www.linkedin.com/in/anna-lindstedt-3a69091/',
        },
        {
          image: 'daniel_ernerot.jpg',
          name: 'Daniel Ernerot',
          title: 'Partner & CTO',
          text: 'Daniel Ernerot has many years of experience from compliance and behavioral change in large organisations in subjects such as sustainability, ethics, quality and information security. He has, on the one hand, worked with content and pedagogy as a senior consultant. And, on the other, with platforms for distribution and follow-up as system owner and architect.',
          email: 'daniel.ernerot@evaluate.se',
          linkedin: 'https://www.linkedin.com/in/danielernerot/',
        },
      ],
      sv: [
        {
          image: 'anna_lindstedt.jpg',
          name: 'Anna Lindstedt',
          title: 'Partner & Senior Advisor',
          text: 'Anna Lindstedt är en av Sveriges absolut främsta experter på strategiskt hållbarhetsarbete i allmänhet och supply chain management i synnerhet. Hon har bland annat i rollen som Senior Advisor, Lead Auditor och tidigare vd på Ethos International hjälpt ett stort antal globala företag med såväl kravställning och screening som fullskaliga audits.',
          email: 'anna.lindstedt@evaluate.se',
          linkedin: 'https://www.linkedin.com/in/anna-lindstedt-3a69091/',
        },
        {
          image: 'daniel_ernerot.jpg',
          name: 'Daniel Ernerot',
          title: 'Partner & CTO',
          text: 'Daniel Ernerot har mångårig erfarenhet från compliance och beteendeförändring i stora företag och organisationer inom ämnen som hållbarhet, etik, kvalitet och informationssäkerhet. Han har arbetat med såväl innehåll och pedagogik som med plattformar för distribution och uppföljning som systemägare och arkitekt.',
          email: 'daniel.ernerot@evaluate.se',
          linkedin: 'https://www.linkedin.com/in/danielernerot/',
        },
      ],
    }
  }),
}
</script>
